import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {},
    comboCountdown: '00:00:00',
    dailyClaimCountdown: '00:00:00',
    wheelCountdown: '00:00:00',
    missions: [],
    nextDailyClaim: Date.now(),
    nextComboClaim: Date.now(),
    nextWheelClaim: Date.now(),
    verifyingMission: [],
    linksDone: [],
    ranks: [],
    leaderboard: [],
    claimUpgrades: [],
    mineUpgrades: [],
    tapUpgrades: [],
    dailyRewards: [],
    energyRefillRatePerSecond: 0, // Initialize energyRefillRatePerSecond
    maxEnergy: 0, // Initialize maxEnergy
    refClaims: [],
    appIcon: '',
    appRewards: {},
    tapClaimReward: 0,
    comboCards: {},
    equipments: [],  // State property for equipments
  },
  
  getters: {
    user: state => state.user,
    missions: state => state.missions,
    nextDailyClaim: state => state.nextDailyClaim,
    verifyingMission: state => state.verifyingMission,
    nextWheelClaim: state => state.nextWheelClaim,
    nextComboClaim: state => state.nextComboClaim,
    linksDone: state => state.linksDone,
    ranks: state => state.ranks,
    leaderboard: state => state.leaderboard,
    claimUpgrades: state => state.claimUpgrades,
    mineUpgrades: state => state.mineUpgrades,
    tapUpgrades: state => state.tapUpgrades,
    dailyRewards: state => state.dailyRewards,
    appIcon: state => state.appIcon,
    refClaims: state => state.refClaims,
    appRewards: state => state.appRewards,
    tapClaimReward: state => state.tapClaimReward,
    comboCards: state => state.comboCards,
    equipments: state => state.equipments, 
    equipmentById: state => id => state.equipments.find(e => e._id === id),  
    energyRefillRatePerSecond: state => state.energyRefillRatePerSecond,
    maxEnergy: state => state.maxEnergy,
    dailyClaimCountdown: state => state.dailyClaimCountdown,
    wheelCountdown: state => state.wheelCountdown,
    comboCountdown: state => state.comboCountdown,
  },
  
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setMissions(state, missions) {
      state.missions = missions;
    },
    setStorage(state, storage) {
      state.user.storage = storage;
    },
    setBalance(state, balance) {
      state.user.balance = balance;
    },
    setComboCountdown(state, countdown) {
      state.comboCountdown = countdown;
    },
    setWheelCountdown(state, countdown) {
      state.wheelCountdown = countdown;
    },
    setDailyCountdown(state, countdown) {
      state.dailyClaimCountdown = countdown;
    },
    setNextDailyClaim(state, nextDailyClaim) {
      state.nextDailyClaim = nextDailyClaim;
    },
    setNextComboClaim(state, nextComboClaim) {
      state.nextComboClaim = nextComboClaim;
    },
    setNextWheelClaim(state, nextWheelClaim) {
      state.nextWheelClaim = nextWheelClaim;
    },
    addVerifyingMission(state, mission) {
      state.verifyingMission.push(mission);
    },
    removeVerifyingMission(state, mission) {
      state.verifyingMission = state.verifyingMission.filter(m => m !== mission);
    },
    addLinkDone(state, link) {
      state.linksDone.push(link);
    },
    removeLinkDone(state, link) {
      state.linksDone = state.linksDone.filter(l => l !== link);
    },
    setRanks(state, ranks) {
      state.ranks = ranks;
    },
    setLeaderboard(state, leaderboard) {
      state.leaderboard = leaderboard;
    },
    setClaimUpgrades(state, claims) {
      state.claimUpgrades = claims;
    },
    setMineUpgrades(state, upgrades) {
      state.mineUpgrades = upgrades;
    },
    setDailyRewards(state, rewards) {
      state.dailyRewards = rewards;
    },
    setAppIcon(state, appIcon) {
      state.appIcon = appIcon;
    },
    setRefClaims(state, refClaims) {
      state.refClaims = refClaims;
    },
    setAppRewards(state, appRewards) {
      state.appRewards = appRewards;
    },
    setTapUpgrades(state, tapUpgrades) {
      state.tapUpgrades = tapUpgrades;
    },
    setTapClaimReward(state, reward) {
      state.tapClaimReward = reward;
    },
    setComboCards(state, cards) {
      state.comboCards = cards;
    },
    setEquipments(state, equipments) {
      state.equipments = equipments;
    },
    setEnergyRefillRatePerSecond(state, rate) { // Mutation for setting energyRefillRatePerSecond
      state.energyRefillRatePerSecond = rate;
    },
    setMaxEnergy(state, max) { // Mutation for setting maxEnergy
      state.maxEnergy = max;
    },
    setEnergy(state, energy) {
      state.user.energy = energy;
    },
  },
  
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setMissions({ commit }, missions) {
      commit('setMissions', missions);
    },
    setStorage({ commit }, storage) {
      commit('setStorage', storage);
    },
    setNextDailyClaim({ commit }, nextDailyClaim) {
      commit('setNextDailyClaim', nextDailyClaim);
    },
    setNextWheelClaim({ commit }, nextWheelClaim) {
      commit('setNextWheelClaim', nextWheelClaim);
    },
    setNextComboClaim({ commit }, nextComboClaim) {
      commit('setNextComboClaim', nextComboClaim);
    },
    setBalance({ commit }, balance) {
      commit('setBalance', balance);
    },
    addVerifyingMission({ commit }, mission) {
      commit('addVerifyingMission', mission);
    },
    removeVerifyingMission({ commit }, mission) {
      commit('removeVerifyingMission', mission);
    },
    addLinkDone({ commit }, link) {
      commit('addLinkDone', link);
    },
    removeLinkDone({ commit }, link) {
      commit('removeLinkDone', link);
    },
    setRanks({ commit }, ranks) {
      commit('setRanks', ranks);
    },
    setLeaderboard({ commit }, leaderboard) {
      commit('setLeaderboard', leaderboard);
    },
    setMineUpgrades({ commit }, upgrades) {
      commit('setMineUpgrades', upgrades);
    },
    setDailyRewards({ commit }, rewards) {
      commit('setDailyRewards', rewards);
    },
    setAppIcon({ commit }, appIcon) {
      commit('setAppIcon', appIcon);
    },
    setRefClaims({ commit }, refClaims) {
      commit('setRefClaims', refClaims);
    },
    setAppRewards({ commit }, appRewards) {
      commit('setAppRewards', appRewards);
    },
    setTapUpgrades({ commit }, tapUpgrades) {
      commit('setTapUpgrades', tapUpgrades);
    },
    setTapClaimReward({ commit }, reward) {
      commit('setTapClaimReward', reward);
    },
    setComboCards({ commit }, cards) {
      commit('setComboCards', cards);
    },
    setEquipments({ commit }, equipment) {
      commit('setEquipments', equipment);
    },
    setEnergyRefillRatePerSecond({ commit }, rate) { // Action for setting energyRefillRatePerSecond
      commit('setEnergyRefillRatePerSecond', rate);
    },
    setMaxEnergy({ commit }, max) { // Action for setting maxEnergy
      commit('setMaxEnergy', max);
    },
    setEnergy({ commit }, energy) {
      commit('setEnergy', energy);
    },
    setComboCountdown({ commit }, countdown) {
      commit('setsetComboCountdown', countdown);
    },
    setWheelCountdown({ commit }, countdown) {
      commit('setWheelCountdown', countdown);
    },
    setDailyCountdown({ commit }, countdown) {
      commit('setDailyCountdown', countdown);
    }
  },
  
  modules: {}
})
