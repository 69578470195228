<template>
    <div class="bg-slate-800 p-2 rounded-xl flex flex-col items-center">
        <img :src="equipment.icon" alt="equipment" style="height: 120px; width: 120px;">
        <h1 class="text-white font-normal">{{ equipment.title }}</h1>
        <div class="flex gap-1 items-center mt-3">
            <h3>Hourly Mine</h3>
            <div class="flex gap-1 items-center justify-center">
                <img :src="store.state.appIcon" alt="appicon" class="relative" style="height: 20px; width: 20px; border-radius: 100%">
                <h4 class="text-white font-bold text-base">{{ formattedAmount }}</h4>
            </div>
        </div>
        <div class="mt-4 text-black w-full rounded-2xl py-2 flex gap-1 items-center justify-center" 
            :class="isDisabled ? 'bg-gray-400' : 'bg-white'" 
            :disabled="isDisabled"
            v-on:click="purchase">
            <h3 class="font-bold text-sm">{{ isPurchased ? 'Purchased' : 'Purchase for' }}</h3>
            <div class="flex items-center" v-if="!isPurchased">
                <img :src="store.state.appIcon" alt="appicon" class="relative" style="height: 15px; width: 15px; border-radius: 100%">
                <h3 class="text-sm font-bold">{{ formattedPrice }}</h3>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import store from '@/store/index';
import userService from '@/service/userService';
import mineService from '@/service/mineService';

const props = defineProps({
    equipment: {
        type: Object,
        required: true,
    }
});

// Function to format numbers to acronyms (e.g., 20000 -> 20k)
function formatNumber(num) {
    if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M';
    if (num >= 1e3) return (num / 1e3).toFixed(0) + 'k';
    return num.toString();
}

// Computed properties for formatted amount and price
const formattedAmount = computed(() => formatNumber(props.equipment.amount));
const formattedPrice = computed(() => formatNumber(props.equipment.price));

const isDisabled = computed(() => {
    return isPurchased.value == true || store.state.user.balance < props.equipment.price;
});

const isPurchased = computed(() => {
    return store.state.user.purchasedEquipments.includes(props.equipment._id);
});

const purchase = async () => {
    if(isDisabled.value == true) return;

    const res = await userService.purchaseEquipment(store.state.user.telegramId, props.equipment._id);

    if(res && res.user) {
        store.commit('setUser', res.user);

        mineService.stopMining();

        const minePerSecond = ((res.user.hourlyRate / 60) / 60);
        mineService.startMining(minePerSecond);
    }
}
</script>
