<template>
    <div class="flex flex-col px-3 h-full">
        <h1 class="text-white font-bold text-2xl mt-5">Boosts</h1>
        <div class="flex justify-start mt-5">
            <h4 class="text-base text-white font-medium text-start">Tap Boosts</h4>
        </div>
        <div class="mt-3 border border-white border-opacity-20 bg-black rounded-2xl p-2 flex items-center justify-between relative overflow-hidden">
            <div class="flex items-center">
                <img :src="currentTapUpgrade?.icon ?? ''" alt="box" style="height: 50px; width: 50px;" class="rounded-full">
                <div class="flex flex-col items-start ms-3 text-white">
                    <h3 class="text-medium">{{ currentTapUpgrade.name }}</h3>
                    <h4 class="text-[#B8B8B8]">Mining</h4>
                    <h4 class="text-[#B8B8B8] text-start text-xs">{{ currentTapUpgrade.description }}</h4>
                </div>
            </div>
            <button class="bg-[#171717] p-3 text-white rounded-xl" v-on:click="showModal(currentTapUpgrade, 'tap')">Upgrade</button>
        </div>
        <dialog id="upgrade_modal" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0">
            <div class="modal-box h-96 rounded-t-2xl p-4 bg-slate-900">
                <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeModal">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                    </svg>
                </button>
                <div class="mt-12">
                    <h3 class="text-xl font-bold text-white">{{ nextUpgrade?.name ?? '' }}</h3>
                    <div class="mt-5 border border-[#3F71D4] bg-[#3F71D4] gap-5 bg-opacity-10 rounded-2xl p-3 flex items-center justify-center relative overflow-hidden">
                        <div class="flex flex-col justify-center items-center me-3">
                            <img :src="selectedUpgrade?.icon ?? ''" alt="currentUpgrade" style="height: 60px; width: 60px;" class="rounded-full">
                            <h1 class="text-white font-medium text-base mt-2">{{ selectedUpgrade?.name ?? '' }}</h1>
                            <div class="flex gap-2 items-center justify-center">
                                <h2 class="text-white font-medium text-base mt-2">{{ selectedUpgrade?.price ?? '' }}</h2>
                                <img :src="store.state.appIcon" alt="appicon" class="h-5 w-5 rounded-full"/>
                            </div>
                        </div>
                        <img src="../assets/arrow.svg" alt="arrow" v-if="nextUpgrade">
                        <div class="flex flex-col justify-center items-center ms-3" v-if="nextUpgrade">
                            <img :src="nextUpgrade?.icon ?? ''" alt="nextUpgrade" style="height: 60px; width: 60px;" class="rounded-full">
                            <h1 class="text-white font-medium text-base mt-2">{{ nextUpgrade?.name ?? '' }}</h1>
                            <div class="flex gap-2 items-center justify-center">
                                <h2 class="text-white font-medium text-base mt-2">{{ nextUpgrade?.price ?? '' }}</h2>
                                <img :src="store.state.appIcon" alt="appicon" class="h-5 w-5 rounded-full"/>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="bg-[#171717] p-3 text-white font-bold w-full rounded-xl mt-5" v-on:click="upgradeTap">Upgrade</button>
            </div>
        </dialog>
        <div class="flex flex-col mt-5">
            <h4 class="text-white font-bold text-xl">Equipments</h4>
            <div class="grid grid-cols-2 gap-2 mt-5">
                <EquipmentCard v-for="equipment in equipments" :key="equipment._id" :equipment="equipment"/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </div>
    </div>
</template>

<script setup>
import store from '@/store/index';
import { computed, ref } from 'vue';
import UserService from '@/service/userService';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import EquipmentCard from '@/components/EquipmentCard.vue';
const selectedUpgrade = ref();
const nextUpgrade = ref();
const selectedClaimType = ref('');
const toast = useToast();

const currentTapUpgrade = computed(() => {
    return store.state.tapUpgrades.find(box => box.level == store.state.user.tapClaimLevel);
});

const equipments = computed(() => {
    return store.state.equipments;
})

const showModal = (upgrade, upgradeType) => {
    selectedUpgrade.value = upgrade;
    selectedClaimType.value = upgradeType;
    nextUpgrade.value = findNextUpgrade(upgrade, upgradeType);
    document.getElementById('upgrade_modal').showModal();
}

const findNextUpgrade = (upgrade, upgradeType) => {
    let upgrades;

    switch (upgradeType) {
        case 'claim':
            upgrades = store.state.claimUpgrades;
            break;
        case 'mine':
            upgrades = store.state.mineUpgrades;
            break;
        case 'tap': 
            upgrades = store.state.tapUpgrades;
            break;
        default:
            break;
    }

    const currentIndex = upgrades.findIndex(u => u._id === upgrade._id);
    return upgrades[currentIndex + 1] || null;
}

const upgradeTap = async () => {
    try {
        const res = await UserService.upgradeTap(store.state.user.telegramId);
        
        if (res.user) {
            store.commit('setUser', res.user);
            store.commit('setTapClaimReward', res.tapClaimReward)
            closeModal();
            
            toast.success('Successfuly upgraded!', { position: 'top', duration: 1500 });
        } 
    } catch(e) {
        console.log(e);
        toast.error(e.response.data.error, { position: 'top', duration: 1500 });
        closeModal();
    }
}

const closeModal = () => {
    document.getElementById('upgrade_modal').close();
}
</script>
