<template>
    <div class="h-screen">
      <div class="flex gap-12 text-white p-4 font-semibold justify-center mt-5">
        <h3>Rewards</h3>
    </div>
    <div class="bg-[#727886] h-[1px] mx-5"></div>
      <div class="p-4 w-screen">
          <div class="flex flex-col items-center">
            <div class="mt-5 w-full border border-[#3F71D4] bg-[#3F71D4] bg-opacity-10 rounded-2xl p-3 flex flex-col justify-between relative overflow-hidden">
              <div class="flex flex-col justify-start items-start">
                <h3 class="text-white text-xl font-semibold">Daily Reward</h3>
                <h2 class="text-[#8E96A6] font-medium">Visit daily to claim your rewards</h2>
              </div>
              <div class="flex justify-between items-center w-full mt-5">
                <div class="flex gap-1">
                  <div v-for="day in 7" :key="day" class="rounded-md bg-black">
                    <div class="m-2">
                      <svg width="12" height="12" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.0762 0.460129L9.97841 1.62638L5.55527 6.12942L4.26651 4.86598L3.12095 3.69972L0.877563 5.98364L2.02312 7.14989L4.40971 9.57959L5.50754 10.7458L6.6531 9.57959L12.2218 3.9103L13.3674 2.74404L11.0762 0.460129Z" :fill="user.dailyClaim >= day ? '#33FF00' : '#888C87'"/>
                      </svg>
                    </div>
                    <div class="bg-[#383838] p-1 rounded-b-md">
                      <h1 class="text-[#BABABA]" style="font-size: 8px;">
                        Day {{ day }}
                      </h1>
                    </div>
                  </div>
                </div>

                <button
                  class="px-8 rounded-xl flex items-center justify-center ms-3 h-10 w-24 cursor-pointer font-bold text-white"
                  :class="isClaimable ? 'bg-[#3F71D4] shadow-[#3F71D4] shadow-md' : 'bg-slate-600 bg-opacity-100'" 
                  v-on:click="showModal"
                  :disabled="!isClaimable"
                  >
                  <h1>{{ isClaimable ? 'Claim' : 'Claimed!' }}</h1>
                </button>
              </div>
            </div>
          </div>
          <dialog id="my_modal_5" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0 text-white">
            <div class="modal-box h-3/5 rounded-t-2xl p-0 bg-slate-900">
                <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeModal">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                    </svg>
                </button>
                <div class="flex flex-col content-center justify-start items-center mt-10">
                  <h3 class="font-semibold text-4xl text-modal-text">Daily Reward</h3>
                  <div class="rounded-md ms-2 px-2 py-1">
                    <h3 class="font-semibold text-base text-primary-gray">Visit daily to claim your rewards</h3>
                  </div>
                </div>

                <div class="flex justify-center items-center mt-7 overflow-hidden">
                  <div class="h-44 bg-slate-700 relative mx-2 text-white rounded-2xl text-center flex flex-col daily-cards">
                    <div class="rounded-full h-16 w-16  border-4 justify-center flex items-center content-center mt-5 mx-8">
                      <div class="absolute bg-[#3A6CCF] w-12 h-12 rounded-full"></div>
                      <h3 class="relative text-black font-bold text-3xl">{{ user.dailyClaim + 1 }}</h3>
                    </div>
                    <div class="relative flex flex-col justify-start items-center">
                        <h1 class="text-white font-semibold text-base mx-8 mt-2">{{ dailyReward(user.dailyClaim + 1)}}</h1>
                        <h2 class="text-gray-400 font-semibold text-base mx-8">$Pepe.fi</h2>
                    </div>
                    <div class="rounded-b-2xl bg-white absolute bottom-0 flex justify-center w-full">
                      <h1 class="text-black text-lg font-semibold">DAY {{ user.dailyClaim + 1 }}</h1>
                    </div>
                  </div>
                </div>
                
                <button class="bg-white rounded-full px-36 py-3 mt-10" v-on:click="claimDaily">
                  <h1 class="text-black font-bold text-2xl">Claim</h1>
                </button>

                <h1 class="text-white text-base font-bold mt-5">00h 00m</h1>
            </div>
          </dialog>
          <div class="mt-5 w-full border border-[#3F71D4] bg-[#3F71D4] bg-opacity-10 rounded-2xl p-3 flex flex-col justify-between relative overflow-hidden text-white">
              <div class="flex flex-col justify-start items-start">
                <h3 class="text-white text-xl font-semibold">Daily Combo</h3>
                <h2 class="text-[#8E96A6] font-medium text-start">Order randomly to claim the correct combination</h2>
              </div>
              <div class="flex justify-between items-center w-full mt-5">
                <div class="flex gap-1 text-white items-center justify-center">
                  Claim
                  <h3>{{ store.state.appRewards.comboReward }}</h3>
                  <img :src="store.state.appIcon" alt="appicon" class="h-4 w-4 rounded-full">
                  Reward
                </div>

                <!-- class="px-8 rounded-xl flex items-center bg-[#3F71D4] justify-center ms-3 h-10 w-24 cursor-pointer font-bold text-white" -->
                <button
                  class="px-8 rounded-xl flex items-center justify-center ms-3 h-10 w-24 cursor-pointer font-bold text-white bg-[#3F71D4] shadow-[#3F71D4] shadow-md"
                  v-on:click="showComboModal"
                  >
                  <h1>Play</h1>
                </button>
                
              
              </div>
          </div>
          <div class="mt-5 w-full border border-[#3F71D4] bg-[#3F71D4] bg-opacity-10 rounded-2xl p-3 flex flex-col justify-between relative overflow-hidden text-white">
              <div class="flex flex-col justify-start items-start">
                <h3 class="text-white text-xl font-semibold">Lucky Card</h3>
                <h2 class="text-[#8E96A6] font-medium text-start">Tap randomly to find your lucky card!</h2>
              </div>
              <div class="flex justify-between items-center w-full mt-5">
                <div class="flex gap-1"></div>

                <button
                  class="px-8 rounded-xl flex items-center justify-center ms-3 h-10 w-24 cursor-pointer font-bold text-white bg-[#3F71D4] shadow-[#3F71D4] shadow-md"
                  v-on:click="showLuckyCardModal"
                  >
                  <h1>Play</h1>
                </button>
              </div>
          </div>
          <dialog id="combo_modal" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0 text-white">
            <div class="modal-box h-3/5 rounded-t-2xl p-0 bg-slate-900">
              <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeComboModal">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                    </svg>
              </button>
              <div class="container mx-auto p-4 w-screen">
                <h1 class="text-2xl mb-4">Daily Combo Cards</h1>
                <div class="flex justify-center w-full ms-1 mt-14">
                  <DropZone :cards="cards" @update:cards="updateCards" />
                </div>
                <h3 class="font-normal text-base mt-10" v-if="!user.isComboClaimed">You have {{ user?.comboAttemptsLeft ?? 0 }} attempts left.</h3>
                <h3 class="font-normal text-base mt-10" v-else>You already claimed daily combo reward.</h3>
                <h4 class="font-normal text-base mt-10">Complete the combination by swiping the images.</h4>
                <button class="bg-white rounded-full w-full py-3 mt-10" v-on:click="tryCombo" v-if="!user.isComboClaimed && user.comboAttemptsLeft != 0">
                  <h1 class="text-black font-bold text-xl">Try Your Chance!</h1>
                </button>
                <button v-else-if="user.isComboClaimed || user.comboAttemptsLeft == 0" disabled class="bg-gray-400 rounded-full w-full py-3 mt-10">
                  <h1 class="text-black font-bold text-xl">You already tried!</h1>
                </button>
              </div>
            </div>
          </dialog>

            <!-- Status Popup -->
          <dialog id="status_modal" class="modal text-white">
            <div class="modal-box h-2/5 rounded-t-2xl p-0 bg-slate-900">
              <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeAttemptModal">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                  </svg>
              </button>
              <div class="p-4 w-full flex flex-col h-full justify-center items-center">
                <h1 class="text-2xl mb-4">Status</h1>
                <p>{{ comboStatus ? 'You won!' : 'Please try again!' }}</p>
                <button class="bg-white rounded-full px-24 py-3 mt-10" v-on:click="closeAttemptModal">
                  <h1 class="text-black font-bold text-2xl">Ok!</h1>
                </button>
              </div>
            </div>
          </dialog>

          <dialog id="luckycard_modal" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0 text-white">
            <div class="modal-box h-3/5 rounded-t-2xl p-0 bg-slate-900">
              <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeLuckyCardModal">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                </svg>
              </button>
              <div class="container mx-auto p-4 w-screen">
                <div class="flex justify-between items-center space space-x-4">
                  <div class="relative">
                    <button class="bg-[#3F71D4] bg-opacity-10 border border-[#3F71D4] text-white p-2 rounded-md flex gap-2 items-center justify-center" v-on:click="isDropdownOpen = !isDropdownOpen">
                      {{ user.usdtBalance }} 
                      <img src="../assets/usdt.svg" alt="usdt" style="height: 16px; width: 16px;">
                    </button>
                    <div
                      v-show="isDropdownOpen" 
                      class="absolute -right-20 mt-1 w-36 bg-slate-900 rounded-md shadow-lg z-50"
                    >
                      <button class="block px-4 py-2 text-white text-center bg-slate-900 rounded-md" v-on:click="withdraw">Withdraw</button>
                    </div>
                  </div>
                  <h1 class="text-2xl">Lucky Card</h1>
                  <div class="w-1/6"></div>
                </div>
                <div class="grid grid-cols-4 gap-4 bg-slate-900 mt-4">
                  <div
                    v-for="(luckyCard, index) in luckyCards"
                    :key="index"
                    class="relative w-20 h-20 rounded-xl cursor-pointer"
                    v-on:click="flipCard(index)"
                  >
                    <div class="flip-card-inner" :class="{ 'flipped': luckyCard.flipped }">
                      <div class="flip-card-front">
                        <div class="w-20 h-20 bg-slate-800 rounded-xl flex justify-center items-center">
                          ?
                        </div>
                      </div>
                      <div class="flip-card-back">
                        <div class="w-20 h-20 border-[#3F71D4] rounded-xl bg-[#3F71D4] bg-opacity-10 flex justify-center gap-2 items-center text-white">
                          <h4 class="flex gap-1">
                            {{ luckyCard.reward }}
                            <h4 v-if="luckyCard.currency == 'usdt'">$</h4>
                          </h4>
                          <img :src="store.state.appIcon" alt="appicon" class="h-4 w-4 rounded-full" v-if="luckyCard.currency == 'pepefi'"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="bg-white rounded-full w-full py-3 mt-10" v-on:click="closeLuckyCardModal">
                  <h1 class="text-black font-bold text-xl">Go Back!</h1>
                </button>
              </div>
            </div>
          </dialog>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import DropZone from '@/components/DropZone.vue';
import UserService from '@/service/userService';
import store from '@/store/index';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

const user = computed(() => {
  return store.state.user;
});

const cards = computed(() => {
  return store.state.comboCards.cards;
}); 

const luckyCards = computed(() => {
  return store.state.luckyCardConfig.cards;
});

const isDropdownOpen = ref(false);

const rewards = computed(() => {
  return store.state.dailyRewards;
});

const comboStatus = ref();

const updateCards = (newCards) => {
  cards.value = newCards;
}

const dailyReward = (day) => {
  return rewards.value.find(reward => reward.day == day).reward;
};

const isClaimable = computed(() => {
  if(store.state.user.dailyClaim == 0) return true;

  return store.state.nextDailyClaim <= Date.now();
});

const showModal = () => {
  const modal = document.getElementById('my_modal_5');

  modal.showModal();
}

const closeModal = () => {
  document.getElementById('my_modal_5').close();
}

const claimDaily = async () => {
  try {
    await UserService.claimDaily(store.state.user.telegramId);
    const res = await UserService.getUser(store.state.user.telegramId);

    document.getElementById('my_modal_5').close();
    
    if(!res.error) {
      store.commit('setUser', res.user);
      store.commit('setNextDailyClaim', res.user);

      return;
    }
  } catch(e) {
    console.log(e);
  }
}

const showComboModal = () => {
  const modal = document.getElementById('combo_modal');

  modal.showModal();
}

const closeComboModal = () => {
  document.getElementById('combo_modal').close();
}

const showAttemptModal = () => {
  const modal = document.getElementById('status_modal');

  modal.showModal();
}

const closeAttemptModal = () => {
  const modal = document.getElementById('status_modal');

  modal.close();

  if(comboStatus.value) {
    closeComboModal();
  }
}

const tryCombo = async () => {
  try {
    const comboIds = cards.value.map(card => card.id).join('');

    const res = await UserService.claimCombo(store.state.user.telegramId, comboIds);

    comboStatus.value = res.success;

    if(res.user) {
      store.commit('setUser', res.user);

      if(res.user.comboAttemptsLeft == 0) {
        closeComboModal();
      }
    }

    showAttemptModal();
  } catch(e) {
    comboStatus.value = false;
    return e;
  }
}

const showLuckyCardModal = () => {
  const modal = document.getElementById('luckycard_modal');

  modal.showModal();
}

const closeLuckyCardModal = () => {
  const modal = document.getElementById('luckycard_modal');

  luckyCards.value.forEach(card => {
    card.flipped = false;
  });

  modal.close();
}

const flipCard = async (index) => {
    if(user.value.luckyCardAttemptsLeft <= 0) return;

    luckyCards.value[index].flipped = true;

    if(user.value.luckyCardAttemptsLeft == 1) {
      luckyCards.value.forEach(card => {
        card.flipped = true;
      })
    }

    await UserService.claimLuckyCard(user.value.telegramId, luckyCards.value[index]._id);
}

const withdraw = () => {
  $toast.error('Insufficient balance', { position: 'top', duration: 1500});
}
</script>

<style scoped>
.flip-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.flip-card-inner.flipped {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flip-card-back {
  transform: rotateY(180deg);
}
</style>
