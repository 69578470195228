let interval = null;
const store = require('@/store/index');

const startMining = async (minePerSecond) => {
    interval = setInterval(() => {
        const user = store.default.state.user;
        user.balance = user.balance + minePerSecond;

        store.default.commit('setUser', user);
    }, 1000);
};

const startEnergyRefill = async () => {
    setInterval(() => {
        if(store.default.state.user.energy >= 1000) {
            return;
        }
        const newEnergy = store.default.state.user.energy + store.default.state.energyRefillRatePerSecond;

        store.default.commit('setEnergy', newEnergy);
    }, 1000);
}

const stopMining = () => {
    clearInterval(interval);
    interval = null;
};

export default { startMining, stopMining, interval, startEnergyRefill };